import _ from '@/utils/lodash'
import { TABLA, SYNC_UPLOAD, SyncError } from '@/utils/consts'
import store from '@/store'

export const CHUNK_SIZE = 25

export const doSync = async ($offline, $api, $loading, $alert, chunkSize = CHUNK_SIZE, maxCalls = 0, silent = false) => {
  const tables = $offline.db.tables
  const idusuario = store.get('usuario/idusuario')
  // llamadas pendientes
  let pendingCalls = $offline.db
    .select()
    .from(tables.sync)
    .innerJoin($offline.sync.dummyTable, $offline.sync.pk.eq($offline.sync.dummyPk))
    .where(
      $offline.db.op.and(
        tables.sync.pending.eq(true),
        $offline.db.op.or(
          tables.sync.idusuario.eq(idusuario),
          // idusuario -> no debería ser nullable pero el upgrade de la BD se podría ejecutar
          // sin un usuario logado
          tables.sync.idusuario.isNull(),
        )
      )
    )
    .orderBy(tables.sync.timestamp)
  if (maxCalls > 0) {
    pendingCalls.limit(maxCalls)
  }
  pendingCalls = await pendingCalls.exec()
  if (pendingCalls.length > 0) {
    const ok = []
    const errors = []
    let idsyncUpload = null
    await store.set('sync/syncing', true)
    if (!silent) {
      $loading.showManual('Sincronizando...')
    }
    try {
      // crear siguiente paquete de cambios (SYNC_UPLOAD)
      const remoteSynUpload = await $api.call(
        'syncUpload.insert',
        {},
        {
          silentMessages: true,
          disableUI: !silent,
        },
      )
      const syncUpload = await $offline.syncUpload.insert({
        idsync_upload: remoteSynUpload.data.result.dataset[0].idsync_upload
      })
      idsyncUpload = syncUpload[0].idsync_upload
      // sincronizar tabla SYNC
      const syncCalls = []
      for (let pendingCall of pendingCalls) {
        syncCalls.push({
          method: 'sync.insert',
          params: {
            values: {
              method: pendingCall.sync.method,
              params: _.cloneDeep(pendingCall.sync.params),
              idsync: pendingCall.sync.idsync,
              idsync_upload: idsyncUpload,
              local_ts: pendingCall.sync.timestamp,
            },
          },
        })
      }
      // Actualiar total de cambios
      await $offline.syncUpload.update({
        idsync_upload: idsyncUpload,
        count_total: syncCalls.length,
      })
      // partir en paquetes de chunkSize llamadas
      let totalChunks = 0
      for (let syncCallsChunk of _.chunk(syncCalls, chunkSize)) {
        totalChunks += syncCallsChunk.length
        if (!silent) {
          $loading.setMessage(`Sincronizando ${totalChunks} de ${syncCalls.length} cambios...`)
        }
        await $api.batchCall(
          syncCallsChunk,
          {
            silentMessages: true,
            silentErrors: true,
            disableUI: !silent,
          }
        )
      }
      // marcar paquete de cambios -> sincronizado
      await $offline.syncUpload.update({
        idsync_upload: idsyncUpload,
        synced: true,
      })
      // incluir cambios en el paquete-> sincronizado
      for (let pendingCall of pendingCalls) {
        await $offline.sync.update({
          idsync: pendingCall.sync.idsync,
          idsync_upload: idsyncUpload,
        })
      }
      // sincronizar cambios
      // incluye un parámetros especiales: __idsync__ / __idsync_upload__
      // para loguear error en el servidor
      const changeCalls = []
      for (let pendingCall of pendingCalls) {
        const params = _.cloneDeep(pendingCall.sync.params)
        params.__idsync_upload__ = idsyncUpload
        params.__idsync__ = pendingCall.sync.idsync
        changeCalls.push({
          id: pendingCall.sync.idsync,
          method: pendingCall.sync.method,
          params: params
        })
      }
      // partir en paquetes de chunkSize llamadas
      totalChunks = 0
      for (let changeCallsChunk of _.chunk(changeCalls, chunkSize)) {
        totalChunks += changeCallsChunk.length
        if (!silent) {
          $loading.setMessage(`Aplicando ${totalChunks} de ${syncCalls.length} cambios...`)
        }
        const changeResponses = await $api.batchCall(
          changeCallsChunk,
          {
            silentMessages: true,
            silentErrors: true,
            disableUI: !silent,
          }
        )
        // actualizar local el resultado de los cambios aplicados
        for (let response of Object.keys(changeResponses.data)) {
          const respCall = changeResponses.data[response]
          if (respCall.error) {
            await $offline.sync.update({
              idsync: respCall.id,
              pending: false,
              result: SYNC_UPLOAD.results.error,
              error_message: respCall.error.toString()
            })
            errors.push(respCall)
          } else {
            await $offline.sync.update({
              idsync: respCall.id,
              pending: false,
              result: SYNC_UPLOAD.results.correcto
            })
            ok.push(respCall)
          }
          // Actualizo la cantidad de OK y erroes
          await $offline.syncUpload.update({
            idsync_upload: idsyncUpload,
            count_ok: ok.length,
            count_errors: errors.length,
          })
        }
      }
      // purgar cambios enviados
      await $offline.db
        .delete()
        .from(tables.sync)
        .where(
          $offline.db.op.and(
            tables.sync.pending.eq(false),
            tables.sync.idsync_upload.eq(idsyncUpload),
          )
        )
        .exec()
      // actualizar total ok/error
      await $offline.syncUpload.update({
        idsync_upload: idsyncUpload,
        count_ok: ok.length,
        count_errors: errors.length,
        errors: JSON.stringify(errors),
      })
    } finally {
      await store.set('sync/syncing', false)
      if (!silent) {
        $loading.hide()
      }
    }
    const resultado = { errors, ok, idsyncUpload }
    if (resultado.errors.length > 0) {
      $alert.showDialogError(`
        La sincronización Nº${resultado.idsyncUpload} (<strong>IMPORTANTE</strong> para soporte técnico)
        se ha realizado pero con errores
        (<span style="color: green">${resultado.ok.length}
        correctos</span> y <span style="color: red">${resultado.errors.length} con error</span>)
      `)
      throw new SyncError(
        `Sincronización Nº${resultado.idsyncUpload} con ${resultado.errors.length} error/es (${process.env.VUE_APP_DOMAIN})`
      )
    } else if (resultado.ok.length > 0 && !silent) {
      $alert.showSnackbarSuccess(`Sincronización finalizada (Nº${resultado.idsyncUpload})`)
    }
    return resultado
  }
}

const getParteClean = async (Vue, idparteTrabajo) => {
  const tables = Vue.$offline.db.tables
  const parte = await Vue.$offline.parteTrabajo.select({
    innerJoins: [
      {
        table: tables.orden_trabajo,
        predicate: tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      },
      {
        table: tables.sistema,
        predicate: tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
      }
    ],
    where: tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo)
  })
  return parte[0]
}

const cleanParte = async (Vue, idparteTrabajo) => {
  const tables = Vue.$offline.db.tables
  // PARTE_TRABAJO_MATSIST
  const respParteMatsist = await Vue.$offline.parteTrabajoMatsist.select({
    where: tables.parte_trabajo_matsist.idparte_trabajo.eq(idparteTrabajo)
  })
  const parteTrabajoMatsist = _.map(respParteMatsist, 'parte_trabajo_matsist.idparte_trabajo_matsist')
  await Vue.$offline.parteTrabajoMatsist.delete({
    where: tables.parte_trabajo_matsist.idparte_trabajo_matsist.in(parteTrabajoMatsist)
  })
  // LPARTE_TRABAJO
  const respLparteTrabajo = await Vue.$offline.lparteTrabajo.select({
    where: tables.lparte_trabajo.idparte_trabajo.eq(idparteTrabajo)
  })
  const lparteTrabajo = _.map(respLparteTrabajo, 'lparte_trabajo.idlparte_trabajo')
  await Vue.$offline.lparteTrabajo.delete({
    where: tables.lparte_trabajo.idlparte_trabajo.in(lparteTrabajo)
  })
  // TIEMPO_TRABAJADO
  const respTiempoTrabajado = await Vue.$offline.tiempoTrabajado.select({
    where: tables.tiempo_trabajado.idparte_trabajo.eq(idparteTrabajo)
  })
  const tiempoTrabajado = _.map(respTiempoTrabajado, 'tiempo_trabajado.idtiempo_trabajado')
  await Vue.$offline.tiempoTrabajado.delete({
    where: tables.tiempo_trabajado.idtiempo_trabajado.in(tiempoTrabajado)
  })
  // FICHERO_PARTE_TRABAJO
  const respFicheroParteTrabajo = await Vue.$offline.fichero.select({
    where: Vue.$offline.db.op.and(
      tables.fichero.id.eq(idparteTrabajo),
      tables.fichero.idtabla.eq(TABLA.parte_trabajo.idtabla)
    )
  })
  const ficheroParteTrabajo = _.map(respFicheroParteTrabajo, 'fichero.idfichero')
  await Vue.$offline.fichero.delete({
    where: tables.fichero.idfichero.in(ficheroParteTrabajo)
  })
  // SOLICITUD_MATERIAL
  const respSolicitudMaterial = await Vue.$offline.solicitudMaterial.select({
    where: tables.solicitud_material.idparte_trabajo.eq(idparteTrabajo)
  })
  const solicitudMaterial = _.map(respSolicitudMaterial, 'solicitud_material.idsolicitud_material')
  await Vue.$offline.solicitudMaterial.delete({
    where: tables.solicitud_material.idsolicitud_material.in(solicitudMaterial)
  })
  // PARTE_TRABAJO_TECNICO
  const respParteTecnico = await Vue.$offline.parteTrabajoTecnico.select({
    where: tables.parte_trabajo_tecnico.idparte_trabajo.eq(idparteTrabajo)
  })
  const parteTrabajoTecnico = _.map(respParteTecnico, 'parte_trabajo_tecnico.idparte_trabajo_tecnico')
  await Vue.$offline.parteTrabajoTecnico.delete({
    where: tables.parte_trabajo_tecnico.idparte_trabajo_tecnico.in(parteTrabajoTecnico)
  })
  // ALMACEN_PARTE_MONTAJE
  const respAlmacenesParte = await Vue.$offline.vAlmacenesParte.select({
    where: tables.v_almacenes_parte.idparte_trabajo.eq(idparteTrabajo)
  })
  const AlmacenesParte = _.map(respAlmacenesParte, 'v_almacenes_parte.idv_almacenes_parte')
  await Vue.$offline.vAlmacenesParte.delete({
    where: tables.v_almacenes_parte.idv_almacenes_parte.in(AlmacenesParte)
  })
  // NOTAS_PARTE_TRABAJO
  const respNotaParteTrabajo = await Vue.$offline.nota.select({
    where: Vue.$offline.db.op.and(
      tables.nota.identificador.eq(idparteTrabajo),
      tables.nota.idtabla.eq(TABLA.parte_trabajo.idtabla)
    )
  })
  const notaParteTrabajo = _.map(respNotaParteTrabajo, 'nota.idnota')
  await Vue.$offline.nota.delete({
    where: tables.nota.idnota.in(notaParteTrabajo)
  })
  // TODO: Comprobar en v_almacenes_parte qué idalmacenes hay, y cotjarlo con los stock, si no es necesario un almacén entconces borrar el stock
  // PARTE_TRABAJO
  await Vue.$offline.parteTrabajo.delete({
    where: tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo)
  })
}

const cleanOrdenTrabajo = async (Vue, idordenTrabajo) => {
  const tables = Vue.$offline.db.tables
  const numParte = await Vue.$offline.db
    .select(
      Vue.$offline.db.fn.count(tables.parte_trabajo.idparte_trabajo).as('cant_partes')
    )
    .from(tables.parte_trabajo)
    .where(tables.parte_trabajo.idorden_trabajo.eq(idordenTrabajo))
    .exec()
  if (numParte[0].cant_partes === 0) {
    const countChecklistOt = await Vue.$offline.checklistOt.select({
      where: tables.checklist_ot.idorden_trabajo.eq(idordenTrabajo)
    })
    if (countChecklistOt.length > 0) {
      // FICHERO_ORDEN_TRABAJO
      const respFicheroOrdenTrabajo = await Vue.$offline.fichero.select({
        where: Vue.$offline.db.op.and(
          tables.fichero.id.eq(idordenTrabajo),
          tables.fichero.idtabla.eq(TABLA.orden_trabajo.idtabla)
        )
      })
      const ficheroOrdenTrabajo = _.map(respFicheroOrdenTrabajo, 'fichero.idfichero')
      await Vue.$offline.fichero.delete({
        where: tables.fichero.idfichero.in(ficheroOrdenTrabajo)
      })
      // ACCION_CORRECTORA
      const respAccionCorrectoraOt = await Vue.$offline.accionCorrectoraOt.select({
        innerJoins: [
          {
            table: tables.tanomalia_checklist_ot,
            predicate: tables.accion_correctora_ot.idtanomalia_checklist_ot.eq(tables.tanomalia_checklist_ot.idtanomalia_checklist_ot)
          },
          {
            table: tables.pregunta_checklist_ot,
            predicate: tables.tanomalia_checklist_ot.idpregunta_checklist_ot.eq(tables.pregunta_checklist_ot.idpregunta_checklist_ot)
          },
          {
            table: tables.grupo_checklist_ot,
            predicate: tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
          },
          {
            table: tables.checklist_ot,
            predicate: tables.grupo_checklist_ot.idchecklist_ot.eq(tables.checklist_ot.idchecklist_ot)
          }
        ],
        where: tables.checklist_ot.idorden_trabajo.eq(idordenTrabajo)
      })
      const accionCorrectoraOt = _.map(respAccionCorrectoraOt, 'accion_correctora_ot.idaccion_correctora_ot')
      await Vue.$offline.accionCorrectoraOt.delete({
        where: tables.accion_correctora_ot.idaccion_correctora_ot.in(accionCorrectoraOt)
      })
      // FICHERO_ACCION_CORRECTORA
      const respFicheroAccionCorrectora = await Vue.$offline.fichero.select({
        where: Vue.$offline.db.op.and(
          tables.fichero.id.in(accionCorrectoraOt),
          tables.fichero.idtabla.eq(TABLA.accion_correctora_ot.idtabla)
        )
      })
      const ficheroAccionCorrectora = _.map(respFicheroAccionCorrectora, 'fichero.idfichero')
      await Vue.$offline.fichero.delete({
        where: tables.fichero.idfichero.in(ficheroAccionCorrectora)
      })
      // TANOMALIA_CHECKLIST_OT
      const respTanomaliaChecklistOt = await Vue.$offline.tanomaliaChecklistOt.select({
        innerJoins: [
          {
            table: tables.pregunta_checklist_ot,
            predicate: tables.tanomalia_checklist_ot.idpregunta_checklist_ot.eq(tables.pregunta_checklist_ot.idpregunta_checklist_ot)
          },
          {
            table: tables.grupo_checklist_ot,
            predicate: tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
          },
          {
            table: tables.checklist_ot,
            predicate: tables.grupo_checklist_ot.idchecklist_ot.eq(tables.checklist_ot.idchecklist_ot)
          }
        ],
        where: tables.checklist_ot.idorden_trabajo.eq(idordenTrabajo)
      })
      const tanomaliaChecklistOt = _.map(respTanomaliaChecklistOt, 'tanomalia_checklist_ot.idtanomalia_checklist_ot')
      await Vue.$offline.tanomaliaChecklistOt.delete({
        where: tables.tanomalia_checklist_ot.idtanomalia_checklist_ot.in(tanomaliaChecklistOt)
      })
      // FICHERO_TANOMALIA_CHECKLIST_OT
      const respFicheroAnomalia = await Vue.$offline.fichero.select({
        where: Vue.$offline.db.op.and(
          tables.fichero.id.in(tanomaliaChecklistOt),
          tables.fichero.idtabla.eq(TABLA.tanomalia_checklist_ot.idtabla)
        )
      })
      const ficheroAnomalia = _.map(respFicheroAnomalia, 'fichero.idfichero')
      await Vue.$offline.fichero.delete({
        where: tables.fichero.idfichero.in(ficheroAnomalia)
      })
      // VALOR_COLUMNA_OT
      const respValorColumnaOt = await Vue.$offline.valorColumnaOt.select({
        innerJoins: [
          {
            table: tables.agrupacion_pregunta_ot,
            predicate: tables.valor_columna_ot.idagrupacion_pregunta_ot.eq(tables.agrupacion_pregunta_ot.idagrupacion_pregunta_ot)
          },
          {
            table: tables.grupo_checklist_ot,
            predicate: tables.agrupacion_pregunta_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
          },
          {
            table: tables.checklist_ot,
            predicate: tables.grupo_checklist_ot.idchecklist_ot.eq(tables.checklist_ot.idchecklist_ot)
          }
        ],
        where: tables.checklist_ot.idorden_trabajo.eq(idordenTrabajo)
      })
      const valorColumnaOt = _.map(respValorColumnaOt, 'valor_columna_ot.idvalor_columna_ot')
      await Vue.$offline.valorColumnaOt.delete({
        where: tables.valor_columna_ot.idvalor_columna_ot.in(valorColumnaOt)
      })
      // PREGUNTA_CHECKLIST_OT
      const respPreguntaChecklistOt = await Vue.$offline.preguntaChecklistOt.select({
        innerJoins: [
          {
            table: tables.grupo_checklist_ot,
            predicate: tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
          },
          {
            table: tables.checklist_ot,
            predicate: tables.grupo_checklist_ot.idchecklist_ot.eq(tables.checklist_ot.idchecklist_ot)
          }
        ],
        where: tables.checklist_ot.idorden_trabajo.eq(idordenTrabajo)
      })
      const preguntaChecklistOt = _.map(respPreguntaChecklistOt, 'pregunta_checklist_ot.idpregunta_checklist_ot')
      await Vue.$offline.preguntaChecklistOt.delete({
        where: tables.pregunta_checklist_ot.idpregunta_checklist_ot.in(preguntaChecklistOt)
      })
      // AGRUPACION_PREGUNTA_OT
      const respAgrupacionPreguntaOt = await Vue.$offline.agrupacionPreguntaOt.select({
        innerJoins: [
          {
            table: tables.grupo_checklist_ot,
            predicate: tables.agrupacion_pregunta_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
          },
          {
            table: tables.checklist_ot,
            predicate: tables.grupo_checklist_ot.idchecklist_ot.eq(tables.checklist_ot.idchecklist_ot)
          }
        ],
        where: tables.checklist_ot.idorden_trabajo.eq(idordenTrabajo)
      })
      const agrupacionPreguntaOt = _.map(respAgrupacionPreguntaOt, 'agrupacion_pregunta_ot.idagrupacion_pregunta_ot')
      await Vue.$offline.agrupacionPreguntaOt.delete({
        where: tables.agrupacion_pregunta_ot.idagrupacion_pregunta_ot.in(agrupacionPreguntaOt)
      })
      // GRUPO_CHECKLIST_OT
      const respGrupoChecklistOt = await Vue.$offline.grupoChecklistOt.select({
        innerJoins: [
          {
            table: tables.checklist_ot,
            predicate: tables.grupo_checklist_ot.idchecklist_ot.eq(tables.checklist_ot.idchecklist_ot)
          }
        ],
        where: tables.checklist_ot.idorden_trabajo.eq(idordenTrabajo)
      })
      const grupoChecklistOt = _.map(respGrupoChecklistOt, 'grupo_checklist_ot.idgrupo_checklist_ot')
      await Vue.$offline.grupoChecklistOt.delete({
        where: tables.grupo_checklist_ot.idgrupo_checklist_ot.in(grupoChecklistOt)
      })
      // CHECKLIST_OT_SUBSIS
      const respChecklistOtSubis = await Vue.$offline.checklistOtSubsis.select({
        innerJoins: [
          {
            table: tables.checklist_ot,
            predicate: tables.checklist_ot_subsis.idchecklist_ot.eq(tables.checklist_ot.idchecklist_ot)
          }
        ],
        where: tables.checklist_ot.idorden_trabajo.eq(idordenTrabajo)
      })
      const checklistOtSubsis = _.map(respChecklistOtSubis, 'checklist_ot_subsis.idchecklist_ot_subsis')
      await Vue.$offline.checklistOtSubsis.delete({
        where: tables.checklist_ot_subsis.idchecklist_ot_subsis.in(checklistOtSubsis)
      })
      // CHECKLIST_OT
      const respChecklistOt = await Vue.$offline.checklistOt.select({
        where: tables.checklist_ot.idorden_trabajo.eq(idordenTrabajo)
      })
      await Vue.$offline.checklistOt.delete({
        where: tables.checklist_ot.idchecklist_ot.eq(respChecklistOt[0].checklist_ot.idchecklist_ot)
      })
    }
    // ACCION_EJEC
    const respAccionEjec = await Vue.$offline.accionEjec.select({
      innerJoins: [
        {
          table: tables.orden_trabajo_matsist,
          predicate: tables.accion_ejec.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
        }
      ],
      where: tables.orden_trabajo_matsist.idorden_trabajo.eq(idordenTrabajo)
    })
    const accionEjec = _.map(respAccionEjec, 'accion_ejec.idaccion_ejec')
    await Vue.$offline.accionEjec.delete({
      where: tables.accion_ejec.idaccion_ejec.in(accionEjec)
    })
    // ORDEN_TRABAJO_MATSIST
    const respOrdenTrabajoMatsist = await Vue.$offline.ordenTrabajoMatsist.select({
      where: tables.orden_trabajo_matsist.idorden_trabajo.eq(idordenTrabajo)
    })
    const ordenTrabajoMatsist = _.map(respOrdenTrabajoMatsist, 'orden_trabajo_matsist.idorden_trabajo_matsist')
    await Vue.$offline.ordenTrabajoMatsist.delete({
      where: tables.orden_trabajo_matsist.idorden_trabajo_matsist.in(ordenTrabajoMatsist)
    })
    // ORDEN_TRABAJO_MANT
    const respOrdenTrabajoMant = await Vue.$offline.ordenTrabajoMant.select({
      where: tables.orden_trabajo_mant.idorden_trabajo.eq(idordenTrabajo)
    })
    const ordenTrabajoMant = _.map(respOrdenTrabajoMant, 'orden_trabajo_mant.idorden_trabajo_mant')
    await Vue.$offline.ordenTrabajoMant.delete({
      where: tables.orden_trabajo_mant.idorden_trabajo_mant.in(ordenTrabajoMant)
    })
    // LORDEN_TRABAJO_DETALLE
    const respLordenTrabajoDetalle = await Vue.$offline.lordenTrabajoDetalle.select({
      innerJoins: [
        {
          table: tables.lorden_trabajo,
          predicate: tables.lorden_trabajo_detalle.idlorden_trabajo.eq(tables.lorden_trabajo.idlorden_trabajo)
        }
      ],
      where: tables.lorden_trabajo.idorden_trabajo.eq(idordenTrabajo)
    })
    const lordenTrabajoDetalle = _.map(respLordenTrabajoDetalle, 'lorden_trabajo_detalle.idlorden_trabajo_detalle')
    await Vue.$offline.lordenTrabajoDetalle.delete({
      where: tables.lorden_trabajo_detalle.idlorden_trabajo_detalle.in(lordenTrabajoDetalle)
    })
    // LORDEN_TRABAJO
    const respLordenTrabajo = await Vue.$offline.lordenTrabajo.select({
      where: tables.lorden_trabajo.idorden_trabajo.eq(idordenTrabajo)
    })
    const lordenTrabajo = _.map(respLordenTrabajo, 'lorden_trabajo.idlorden_trabajo')
    await Vue.$offline.lordenTrabajo.delete({
      where: tables.lorden_trabajo.idlorden_trabajo.in(lordenTrabajo)
    })
    // ORDEN_TRABAJO_SUBSIS
    const respOrdenTrabajoSubsis = await Vue.$offline.ordenTrabajoSubsis.select({
      where: tables.orden_trabajo_subsis.idorden_trabajo.eq(idordenTrabajo)
    })
    const ordenTrabajoSubsis = _.map(respOrdenTrabajoSubsis, 'orden_trabajo_subsis.idorden_trabajo_subsis')
    await Vue.$offline.ordenTrabajoSubsis.delete({
      where: tables.orden_trabajo_subsis.idorden_trabajo_subsis.in(ordenTrabajoSubsis)
    })
    // ANTICIPOCLI
    const respAnticipocli = await Vue.$offline.anticipocli.select({
      where: tables.anticipocli.idorden_trabajo.eq(idordenTrabajo)
    })
    const anticipocli = _.map(respAnticipocli, 'anticipocli.idanticipocli')
    await Vue.$offline.anticipocli.delete({
      where: tables.anticipocli.idanticipocli.in(anticipocli)
    })
    // NOTAS_ORDEN_TRABAJO
    const respNotaOrdenTrabajo = await Vue.$offline.nota.select({
      where: Vue.$offline.db.op.and(
        tables.nota.identificador.eq(idordenTrabajo),
        tables.nota.idtabla.eq(TABLA.orden_trabajo.idtabla)
      )
    })
    const notaOrdenTrabajo = _.map(respNotaOrdenTrabajo, 'nota.idnota')
    await Vue.$offline.nota.delete({
      where: tables.nota.idnota.in(notaOrdenTrabajo)
    })
    // ORDEN_TRABAJO
    await Vue.$offline.ordenTrabajo.delete({
      where: tables.orden_trabajo.idorden_trabajo.eq(idordenTrabajo)
    })
  }
}

const cleanBancoPrecio = async (Vue, idbancoPrecio) => {
  const tables = Vue.$offline.db.tables
  const numOT = await Vue.$offline.db
    .select(
      Vue.$offline.db.fn.count(tables.orden_trabajo.idorden_trabajo).as('cant_ot')
    )
    .from(tables.orden_trabajo)
    .where(tables.orden_trabajo.idbanco_precio.eq(idbancoPrecio))
    .exec()
  if (numOT[0].cant_ot === 0) {
    // LBANCO_PRECIO_DETALLE
    const respLbancoPrecioDetalle = await Vue.$offline.lbancoPrecioDetalle.select({
      innerJoins: [
        {
          table: tables.lbanco_precio,
          predicate: tables.lbanco_precio_detalle.idlbanco_precio.eq(tables.lbanco_precio.idlbanco_precio)
        }
      ],
      where: tables.lbanco_precio.idbanco_precio.eq(idbancoPrecio)
    })
    const lbancoPrecioDetalle = _.map(respLbancoPrecioDetalle, 'lbanco_precio_detalle.idlbanco_precio_detalle')
    await Vue.$offline.lbancoPrecioDetalle.delete({
      where: tables.lbanco_precio_detalle.idlbanco_precio_detalle.in(lbancoPrecioDetalle)
    })
    // LBANCO_PRECIO
    const respLbancoPrecio = await Vue.$offline.lbancoPrecio.select({
      where: tables.lbanco_precio.idbanco_precio.eq(idbancoPrecio)
    })
    const lbancoPrecio = _.map(respLbancoPrecio, 'lbanco_precio.idlbanco_precio')
    await Vue.$offline.lbancoPrecio.delete({
      where: tables.lbanco_precio.idlbanco_precio.in(lbancoPrecio)
    })
    // BANCO_PRECIO_CAPITULO
    const respBancoPrecioCapitulo = await Vue.$offline.bancoPrecioCapitulo.select({
      where: tables.banco_precio_capitulo.idbanco_precio.eq(idbancoPrecio)
    })
    const bancoPrecioCapitulo = _.map(respBancoPrecioCapitulo, 'banco_precio_capitulo.idbanco_precio_capitulo')
    await Vue.$offline.bancoPrecioCapitulo.delete({
      where: tables.banco_precio_capitulo.idbanco_precio_capitulo.in(bancoPrecioCapitulo)
    })
    // BANCO_PRECIO_GRUPO_DTO
    const respBancoPrecioGrupoDto = await Vue.$offline.bancoPrecioGrupoDto.select({
      where: tables.banco_precio_grupo_dto.idbanco_precio.eq(idbancoPrecio)
    })
    const bancoPrecioGrupoDto = _.map(respBancoPrecioGrupoDto, 'banco_precio_grupo_dto.idbanco_precio_grupo_dto')
    await Vue.$offline.bancoPrecioGrupoDto.delete({
      where: tables.banco_precio_grupo_dto.idbanco_precio_grupo_dto.in(bancoPrecioGrupoDto)
    })
    // BANCO_PRECIO
    await Vue.$offline.bancoPrecio.delete({
      where: tables.banco_precio.idbanco_precio.eq(idbancoPrecio)
    })
  }
}

const cleanSistema = async (Vue, idsistema) => {
  const tables = Vue.$offline.db.tables
  const numOt = await Vue.$offline.db
    .select(
      Vue.$offline.db.fn.count(tables.orden_trabajo.idorden_trabajo).as('cant_ot')
    )
    .from(tables.orden_trabajo)
    .innerJoin(
      tables.sistema,
      tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
    )
    .where(tables.orden_trabajo.idsistema.eq(idsistema))
    .exec()
  if (numOt[0].cant_ot === 0) {
    // DATO_MATERIAL_SISTEMA
    const respDatoMaterialSistema = await Vue.$offline.datoMaterialSistema.select({
      innerJoins: [
        {
          table: tables.material_sistema,
          predicate: tables.dato_material_sistema.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
        },
        {
          table: tables.subsis,
          predicate: tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
        }
      ],
      where: tables.subsis.idsistema.eq(idsistema)
    })
    const datoMaterialSistema = _.map(respDatoMaterialSistema, 'dato_material_sistema.iddato_material_sistema')
    await Vue.$offline.datoMaterialSistema.delete({
      where: tables.dato_material_sistema.iddato_material_sistema.in(datoMaterialSistema)
    })
    // MATERIAL_SISTEMA
    const respMaterialSistema = await Vue.$offline.materialSistema.select({
      innerJoins: [
        {
          table: tables.subsis,
          predicate: tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
        }
      ],
      where: tables.subsis.idsistema.eq(idsistema)
    })
    const materialSistema = _.map(respMaterialSistema, 'material_sistema.idmaterial_sistema')
    await Vue.$offline.materialSistema.delete({
      where: tables.material_sistema.idmaterial_sistema.in(materialSistema)
    })
    // SUBSIS
    const respSubsis = await Vue.$offline.subsis.select({
      where: tables.subsis.idsistema.eq(idsistema)
    })
    const subsis = _.map(respSubsis, 'subsis.idsubsis')
    await Vue.$offline.subsis.delete({
      where: tables.subsis.idsubsis.in(subsis)
    })
    // SISTEMA_MANT
    const respSistemaMant = await Vue.$offline.sistemaMant.select({
      where: tables.sistema_mant.idsistema.eq(idsistema)
    })
    const sistemaMant = _.map(respSistemaMant, 'sistema_mant.idsistema_mant')
    await Vue.$offline.sistemaMant.delete({
      where: tables.sistema_mant.idsistema_mant.in(sistemaMant)
    })
    // SISTEMA_TELEFONO
    const respSistemaTelefono = await Vue.$offline.sistemaTelefono.select({
      where: tables.sistema_telefono.idsistema.eq(idsistema)
    })
    const sistemaTelefono = _.map(respSistemaTelefono, 'sistema_telefono.idsistema_telefono')
    await Vue.$offline.sistemaTelefono.delete({
      where: tables.sistema_telefono.idsistema_telefono.in(sistemaTelefono)
    })
    // SISTEMA_CUOTA
    const respSistemaCuota = await Vue.$offline.sistemaCuota.select({
      where: tables.sistema_cuota.idsistema.eq(idsistema)
    })
    const sistemaCuota = _.map(respSistemaCuota, 'sistema_cuota.idsistema_cuota')
    await Vue.$offline.sistemaCuota.delete({
      where: tables.sistema_cuota.idsistema_cuota.in(sistemaCuota)
    })
    // DATO_SISTEMA
    const respDatoSistema = await Vue.$offline.datoSistema.select({
      where: tables.dato_sistema.idsistema.eq(idsistema)
    })
    const datoSistema = _.map(respDatoSistema, 'dato_sistema.iddato_sistema')
    await Vue.$offline.datoSistema.delete({
      where: tables.dato_sistema.iddato_sistema.in(datoSistema)
    })
    // SISTEMA_TVIACOMUNICACION
    const respSistemaTviacomunicacion = await Vue.$offline.sistemaTviacomunicacion.select({
      where: tables.sistema_tviacomunicacion.idsistema.eq(idsistema)
    })
    const sistemaTviacomunicacion = _.map(respSistemaTviacomunicacion, 'sistema_tviacomunicacion.idsistema_tviacomunicacion')
    await Vue.$offline.sistemaTviacomunicacion.delete({
      where: tables.sistema_tviacomunicacion.idsistema_tviacomunicacion.in(sistemaTviacomunicacion)
    })
    // SISTEMA
    await Vue.$offline.sistema.delete({
      where: tables.sistema.idsistema.eq(idsistema)
    })
  }
}

const cleanCliente = async (Vue, idcliente) => {
  const tables = Vue.$offline.db.tables
  const numSistema = await Vue.$offline.db
    .select(
      Vue.$offline.db.fn.count(tables.sistema.idsistema).as('cant_sistema')
    )
    .from(tables.sistema)
    .where(tables.sistema.idcliente.eq(idcliente))
    .exec()
  if (numSistema[0].cant_sistema === 0) {
    // CLIENTE_DIRECCION
    const respClienteDireccion = await Vue.$offline.clienteDireccion.select({
      where: tables.cliente_direccion.idcliente.eq(idcliente)
    })
    const clienteDireccion = _.map(respClienteDireccion, 'cliente_direccion.idcliente_direccion')
    await Vue.$offline.clienteDireccion.delete({
      where: tables.cliente_direccion.idcliente_direccion.in(clienteDireccion)
    })
    // CLIENTE_TELEFONO
    const respClienteTelefono = await Vue.$offline.clienteTelefono.select({
      where: tables.cliente_telefono.idcliente.eq(idcliente)
    })
    const clienteTelefono = _.map(respClienteTelefono, 'cliente_telefono.idcliente_telefono')
    await Vue.$offline.clienteTelefono.delete({
      where: tables.cliente_telefono.idcliente_telefono.in(clienteTelefono)
    })
    // CLIENTE_AVISO
    const respClienteAviso = await Vue.$offline.clienteAviso.select({
      where: tables.cliente_aviso.idcliente.eq(idcliente)
    })
    const clienteAviso = _.map(respClienteAviso, 'cliente_aviso.idcliente_aviso')
    await Vue.$offline.clienteAviso.delete({
      where: tables.cliente_aviso.idcliente_aviso.in(clienteAviso)
    })
    // CLIENTE
    await Vue.$offline.cliente.delete({
      where: tables.cliente.idcliente.eq(idcliente)
    })
  }
}

export const cleanParteTrabajo = async (Vue, id) => {
  // parte, OT, sistema y cliente
  const parte = await getParteClean(Vue, id)
  if (parte) {
    await cleanParte(Vue, id)
    if (parte.orden_trabajo) {
      await cleanOrdenTrabajo(Vue, parte.orden_trabajo.idorden_trabajo)
      if (parte.orden_trabajo.idbanco_precio) {
        await cleanBancoPrecio(Vue, parte.orden_trabajo.idbanco_precio)
      }
    }
    if (parte.sistema) {
      await cleanSistema(Vue, parte.sistema.idsistema)
      await cleanCliente(Vue, parte.sistema.idcliente)
    }
  }
}

export const uploadParteTrabajo = async (Vue, id) => {
  const tables = Vue.$offline.db.tables
  const parteTrabajoTecnico = await Vue.$offline.parteTrabajoTecnico.select({
    where: Vue.$offline.db.op.and(
      tables.parte_trabajo_tecnico.idparte_trabajo.eq(id),
      tables.parte_trabajo_tecnico.idtecnico.eq(store.get('usuario/idtecnico'))
    )
  })
  const idparteTrabajoTecnico = parteTrabajoTecnico[0].parte_trabajo_tecnico.idparte_trabajo_tecnico
  await Vue.$offline.parteTrabajo.updateParteTrabajoSubidoSync(idparteTrabajoTecnico)
  await cleanParteTrabajo(Vue, id)
}
